import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { Hotkey, hotkeyColors } from 'src/models/hotkeys'
import { HotkeyForm } from './HotkeyForm'
import HotkeyInput from './HotkeyInput'
import { RemoveIcon, ReplayIcon } from '@verbit-ai/icons-library'
import { Divider, Flex, List, ListItem, Stack } from '@chakra-ui/react'
import { IconButton, OptionType } from '@verbit-ai/verbit-ui-library'
import styled from 'styled-components/macro'
import { debounce } from 'lodash'
import { ColorSelect } from 'src/components/Select/ColorSelect'
import { NameInput } from './NameInput'
import { useState } from 'react'
import { DeleteHotkeyConfirmation } from './DeleteHotkeyConfirmation'

const StyledRemoveButton = styled(IconButton)`
    width: 40px;
    margin-left: 16px;
`

export const StyledInputStack = styled(Stack)`
    width: 192px;
`

const StyledSelectStack = styled(Stack)`
    width: 192px;
    margin-left: 16px;
`

const StyledReplay = styled(ReplayIcon)`
    margin: 12px 8px;
`

const StyledDivider = styled(Divider)`
    margin: 22px 0;
`
const StyledFlex = styled(Flex)`
    height: 65px;
`
const StyledList = styled(List)`
    max-height: 40vh;
    overflow-y: scroll;
    padding-right: 20px;
`

export const HotkeysTab = () => {
    const {
        roleHotkeys,
        customRole,
        focusedRole,
        addRoleHotkeys,
        updateRoleHotkeys,
        deleteRoleHotkeys,
    } = useRoleHotkeys()

    const [idToDelete, setIdToDelete] = useState<string>()

    const addRole = async (hotkey: Hotkey) => {
        await addRoleHotkeys(hotkey)
    }

    const getAvailableColors = (currentColor?: string) => {
        const selectedColors = roleHotkeys?.map((item) => item.color)
        return hotkeyColors.filter((hotkeyColor) => {
            if (hotkeyColor.value === currentColor) {
                return true
            }
            const isColorTaken = selectedColors?.find(
                (selectedColor) => selectedColor === hotkeyColor.value,
            )
            return !isColorTaken
        })
    }

    const handleDelete = async () => {
        if (!!idToDelete) {
            await deleteRoleHotkeys(idToDelete)
        }
        setIdToDelete(undefined)
    }

    return (
        <>
            <HotkeyForm onSubmit={addRole} hotkeyName={customRole} />
            <StyledDivider />
            <StyledList>
                {roleHotkeys &&
                    roleHotkeys.map((hotkey) => (
                        <ListItem key={hotkey.id}>
                            <StyledFlex>
                                <StyledInputStack>
                                    <NameInput
                                        onBlur={(value) =>
                                            value &&
                                            value !== hotkey.name &&
                                            updateRoleHotkeys({ ...hotkey, name: value })
                                        }
                                        value={hotkey.name}
                                        autoFocus={focusedRole === hotkey.name}
                                        hotkeyId={hotkey.id}
                                    />
                                </StyledInputStack>
                                <StyledReplay />
                                <StyledInputStack>
                                    <HotkeyInput
                                        initialValue={hotkey.value}
                                        hotkeyId={hotkey.id}
                                        onBlur={(value) =>
                                            value &&
                                            value !== hotkey.value &&
                                            updateRoleHotkeys({ ...hotkey, value: value })
                                        }
                                    />
                                </StyledInputStack>
                                <StyledSelectStack>
                                    <ColorSelect
                                        getColorOptions={(selectedColor) =>
                                            getAvailableColors(selectedColor)
                                        }
                                        selectedColor={hotkey.color}
                                        onChange={(option) =>
                                            updateRoleHotkeys({
                                                ...hotkey,
                                                color: (option as OptionType)?.value,
                                            })
                                        }
                                    />
                                </StyledSelectStack>
                                <StyledRemoveButton
                                    variant={'secondarySubtle'}
                                    onClick={() => setIdToDelete(hotkey.id)}
                                >
                                    <RemoveIcon />
                                </StyledRemoveButton>
                            </StyledFlex>
                        </ListItem>
                    ))}
            </StyledList>
            <DeleteHotkeyConfirmation
                isOpen={!!idToDelete}
                onClose={(isConfirmed) => (isConfirmed ? handleDelete() : setIdToDelete(undefined))}
            />
        </>
    )
}
